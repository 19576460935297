import {
  FC,
  ReactNode,
  useEffect,
  useState,
} from "react";
import Link from "next/link";
import { usePathname } from "next/navigation";

import ListItem from "apps/website/components/base/ListItem/ListItem";
import Text from "apps/website/components/base/Text/Text";
import { IHeaderLink } from "apps/website/components/base/Button/Button.map";

import Icon from "../../base/Icon/Icon";
import Image from "../../base/Image/Image";

export interface INavigationItem {
  item: IHeaderLink,
  className?: string;
  tabIndex?: number;
  children?: ReactNode;
  onClick?: () => void;
  handleShowChildren?: (shouldShow: boolean) => void;
  hasChildren?: boolean;
  disableToggle?: boolean;
}

const NavigationItem: FC<INavigationItem> = ({
  item,
  className,
  tabIndex,
  onClick,
  hasChildren,
  handleShowChildren,
  children,
  disableToggle = false,
}) => {

  const path = usePathname();
  const isActiveParentPath = `/${path.split("/")[1]}` === item.to;
  const isLocalPath = item.to.startsWith("/") && !item.to.startsWith("/manage-plan");

  const AnchorTag = () => (
    <a tabIndex={tabIndex} href={item.to} className="relative" onClick={onClick}>
      <Text tag="div" display="title" size={{ default: "2xl", md: "sm", xl: "default" }} align={{ xl: "center" }}>{ item.name }</Text>
      <div className={`hidden xl:block absolute bottom-0 left-0 h-fine-line group-hover:w-full transition-all duration-300 bg-black ${isActiveParentPath ? "w-full" : "w-0"}`}></div>
    </a>
  );

  const [ showChildren, setShowChildren ] = useState(false);

  useEffect(() => {
    if (!handleShowChildren) return;
    handleShowChildren(showChildren);
  }, [ showChildren ]);

  return (
    <ListItem
      key={item.name}
      component="NavigationItem"
      className={`px-4 xl:py-2 xl:px-4 xl:first:pl-0 xl:pl-0 xl:p-4 group ${className}`}
      align="center"
    >
      <div className="w-full pb-4 xl:pb-0">
        <div className="xl:relative flex items-center justify-between pb-4 border-b border-solid border-dark-grey xl:border-b-0 xl:pb-0">
          { item.type === "authMenu" && (
            <div className="w-11 mr-1">
              <Image image={item.image || { src: "/images/account/account-avatar-fallback.svg", height: 100, width: 100 }} alt="User avatar"/>
            </div>
          ) }
          { hasChildren ? (
            <>
              { disableToggle ? (
                <div className="w-full">
                  <Text tag="div" display="title" size={{ default: item.type === "authMenu" ? "default" : "2xl", md: "sm", xl: "default" }} align={{ xl: "center" }}>{ item.name }</Text>
                </div>
              ) : (
                <button tabIndex={tabIndex} className="relative flex justify-between items-center w-full" onClick={() => setShowChildren(!showChildren)}>
                  <Text tag="div" display="title" size={{ default: item.type === "authMenu" ? "default" : "2xl", md: "sm", xl: "default" }} align={{ xl: "center" }}>{ item.name }</Text>
                  <div className={`transform transition-transform duration-300 xl:hidden ${showChildren ? "rotate-180" : "rotate-0"}`}>
                    <Icon
                      icon="arrowDown"
                      size="xsmall"
                    />
                  </div>
                  <div className={`hidden xl:block absolute bottom-0 left-0 h-fine-line group-hover:w-full transition-all duration-300 bg-black ${isActiveParentPath ? "w-full" : "w-0"}`}></div>
                </button>
              ) }

              <div className="hidden xl:block xl:transition-all xl:ease-in-out xl:duration-300 xl:absolute -bottom-4 xl:-bottom-7 xl:left-1/2 xl:-translate-x-1/2 xl:max-h-0 overflow-hidden xl:group-hover:max-h-4 xl:group-focus-within:max-h-4">
                <div className={`xl:border-transparent xl:border-8 ${item.type === "authMenu" ? "xl:border-b-black" : "xl:border-b-light-grey"}`}></div>
              </div>
            </>
          ) : (
            <>
              { isLocalPath ? (
                <Link href={item.to} legacyBehavior>
                  <AnchorTag />
                </Link>
              ) : (
                <AnchorTag />
              ) }
            </>
          ) }
        </div>
        { children }
      </div>
    </ListItem>
  );
};

export default NavigationItem;
